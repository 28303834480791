<template>
    <div v-if="action">
        <div class="py-2">
            <div>
                <property-or-field @input="change" :action="action" fieldId="client_id" v-model="actionData['client_id']"></property-or-field>
                <property-or-field @input="change" :action="action" fieldId="from" v-model="actionData['from']"></property-or-field>
                <property-or-field @input="change" :action="action" fieldId="to" v-model="actionData['to']"></property-or-field>
                <property-or-field @input="change" :action="action" fieldId="include_cms" v-model="actionData['include_cms']"></property-or-field>
            </div>
        </div>
        <custom-button @click.native="saveData" :loading="loading">Export</custom-button>
    </div>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField";
    import fileDownload from "js-file-download";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    let follow = Siren.Helper.follow;
    export default {
        data() {
            return {
                loading:false,
                action: null
            }
        },
        components: {
            CustomButton,
            PropertyOrField,
        },
        computed: {
            actionData(){
                var that = this;
                if(!this.action){
                    return {};
                }
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        },
        props:{
            action:{
                type: Object,
                default: null
            }
        },
        mounted () {
            // Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            // Siren.get(process.env.VUE_APP_API_URL + "/api/")
            //     .then(follow("orders"))
            //     .then(res => {
            //         let siren = res.body;
            //         this.action = siren.actions.filter(action => action.name === 'get-report').get('get-report');
            //     }, this);

        },
        methods: {
            change(){
                this.$emit('change');
            },
            update(){
                return this.action.perform(this.actionData);
            },
            async saveData() {
                if(!this.loading) {
                    this.loading = true;
                    this.update().then(res => {
                        if (res.status === 204) {
                            alert('No matching orders were found.');
                            this.loading = false;
                            return;
                        }
                        if (res.type === 'text/csv') {
                            fileDownload(res.text, 'export.csv');
                        }
                        this.$store.dispatch(
                            "setMessage",
                            'The export was processed successfully'
                        );
                        this.loading = false;
                    }).catch(error => {
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                        this.loading = false;
                    })
                }
            },
        },
    }
</script>

<style>
    .reports-form label.block{display:none;}
    .reports-form .form-group label{display:block;}
</style>
